<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <VerifySteps email="true" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h1>Verify account</h1>
        <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.result.messages" />
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="verifyCode"
            @change="tokenChangeHandle"
            v-model="token"
            placeholder="Verify Code"
          />
          <span class="input-group-text">
            <div
              class="spinner-border spinner-border-sm text-secondary"
              role="status"
              v-if="$data.ajax.isVerifying"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <i class="fas fa-keyboard text-danger" v-else></i>
          </span>
          <!--            :value="$data.token"-->
        </div>
        <div class="mt-3">
          <LoadingIcons v-if="$data.ajax.isVerifying" />
          <button
            class="btn btn-primary w-100"
            id="btnLoginModal"
            @click="btnVerifyClick"
            type="submit"
            v-else
          >
            Verify
          </button>
        </div>
        <div v-if="$data.ajax.isVerifying"></div>
        <div v-else>
          <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.result.messages" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
import VerifySteps from './VerifySteps';
import Cookies from 'js-cookie';
export default {
  name: 'EmailVerify',
  components: { LoadingIcons, ErrorFormField, VerifySteps },
  data: function () {
    return {
      // qa: [],
      token: '',
      ajax: {
        isVerifying: false,
      },
      result: {
        status: '',
        messages: [],
      },
    };
  },
  created() {
    let token = this.$route.query.token;
    console.log('token', token);
    if (token == undefined) {
      token = this.$route.params.token;
    }
    console.log('token2 ', token);
    this.$data.token = token;
  },
  methods: {
    tokenChangeHandle: function () {
      this.$data.token = document.getElementById('verifyCode').value;
    },
    btnVerifyClick: async function () {
      // let token = this.$route.query.token;
      let token = this.$data.token;
      // if (token == undefined) {
      //   this.$router.push('/');
      // }
      this.$data.ajax.isVerifying = true;
      console.log(this.$route.query.token); // outputs 'yay'
      let self = this;
      // console.log("state $store ", this.$store.state)
      let apiUrl = process.env.VUE_APP_API_URL;

      await fetch(apiUrl + 'email-verify', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: 'token=' + encodeURI(token),
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          // let messages = resData.messages
          // if (resData.status != 'success') {
          //   alert('error while geting data ' + resData.message);
          //   return;
          // }
          //
          // self.$data.result.status = resData.status;
          // self.$data.result.messages = resData.messages;
          // self.$data.ajax.isVerifying = false;

          let messages = resData.messages;
          if (resData.status == 'error') {
            if (messages.common.length > 0) self.$data.commonErrors = messages.common;
            else self.$data.commonErrors = [];

            console.log('self.$data ', self.$data);
            self.$data.ajax.isVerifying = false;
            return false;
          }
          Cookies.set('name', resData.name);
          Cookies.set('image', resData.image);
          Cookies.set('token', resData.token);
          Cookies.set('role', resData.role);
          Cookies.set('totalNotifications', resData.totalNotifications);
          sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
          Cookies.set('currentRole', 'seeker');
          Cookies.set('active', resData.active);
          Cookies.set('email_verify', resData.email_verify);
          Cookies.set('phone_verify', resData.phone_verify);
          self.$store.commit('emailVerifyUpdate', resData);
          self.$data.ajax.isVerifying = false;
          // self.$store.dispatch('loginUpdate', resData)
          console.log('verify email success');
          self.$router.push('/phone-verify');
        })
        .catch(function (err) {
          console.log('error', err);
          self.$data.ajax.isVerifying = false;
        });
    },
  },
  mounted() {
    //from your component
    if (this.$store.state.active == 1) {
      this.$router.push('/');
      return;
    }
    if (this.$store.state.email_verify == 1) {
      this.$router.push('/phone-verify');
      return;
    }
  },
};
</script>

<style scoped></style>
